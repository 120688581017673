<template>
  <FibrProductBanner
    :title="banner.title"
    :description="banner.description"
    :imageUrl="banner.imageUrl"
  ></FibrProductBanner>
  <div class="horizontal-page-margin vertical-page-margin">
    <div class="section-margin">
      <div class="row">
        <div v-for="feature in features" :key="feature.id" class="col-md-4">
          <FeatureDescription
            :title="feature.title"
            :description="feature.description"
          ></FeatureDescription>
        </div>
      </div>
    </div>
    <SecondaryFeature
      class="section-margin"
      :title="secondaryFeature.title"
      :description="secondaryFeature.description"
      :imageUrl="secondaryFeature.imageUrl"
    >
    </SecondaryFeature>
    <FeatureDescription
      class="section-margin"
      :title="addons.title"
      :description="addons.description"
    >
    </FeatureDescription>
    <ImageWithText
      class="section-margin-small"
      :leftAlignImage="false"
      :imageUrl="fibrAddon1.imageUrl"
    >
      <div class="fibr-addon-title">{{ fibrAddon1.title }}</div>
      <RichText :document="fibrAddon1.document"></RichText>
    </ImageWithText>
    <ImageWithText class="section-margin" :imageUrl="fibrAddon2.imageUrl">
      <div class="fibr-addon-title">{{ fibrAddon2.title }}</div>
      <RichText :document="fibrAddon2.document"></RichText>
    </ImageWithText>
    <FeatureDescription
      class="section-margin"
      :title="speed.title"
      :description="speed.description"
    >
    </FeatureDescription>
    <div
      class="justify-content-center align-items-start gap-4 px-auto my-4"
      :style="{
        display: 'flex',
        flexWrap: 'wrap',
        transition: 'height 800ms ease-in-out',
      }"
    >
      <div
        v-for="bundle in bundles"
        :key="bundle.name"
        :class="{ 'bundle-card-expanded': bundle.isOpen }"
        class="px-3 shadow-lg py-3 rounded-3 bundle-card"
      >
        <h4>{{ bundle.name.split("- ")[1] }}</h4>
        <p>{{ bundle.description }}</p>
        <p
          :style="{ color: '#635890', fontSize: '30px', marginBottom: '0' }"
          class="fw-bolder"
        >
          {{ bundle.price }}
        </p>
        <span
          :style="{ color: '#635890', marginBottom: '8px', fontSize: '15px' }"
          >{{ bundle.vat }}</span
        ><br />
        <button
          @click="() => cardClicked(bundle)"
          type="button"
          :style="{
            marginBottom: '9px',
            marginTop: '9px',
            backgroundColor: '#635890',
            border: 'none',
            color: '#fff',
            padding: '8px 0px',
            borderRadius: '15px',
            fontWeight: '700',
            width: '100%',
          }"
        >
          Get Started
        </button>
        <div v-show="bundle.isOpen" class="bundle-details">
          <BundleDetailsLong
            class="details"
            :bundleId="bundle.id"
          ></BundleDetailsLong>
        </div>
        <button
          @click="toggleDetails(bundle)"
          type="button"
          class="toggle-button"
        >
          {{ bundle.isOpen ? "See less" : "View details" }} <br />
          <img
            class="view-details-arrow"
            :class="{ 'view-details-arrow-open': bundle.isOpen }"
            :src="require('@/assets/icons/purple_arrow.svg')"
          />
        </button>
      </div>
    </div>
    <div
      class="flex link-style my-5"
      style="
        width: 100% !important;
        justify-content: center;
        align-items: center;
        display: flex;
      "
    >
      <a
        :href="viewAll"
        class="text-white py-2 px-4 rounded-4 text-decoration-none shadow-lg"
        style="background-color: #635890; font-weight: 900"
        >Looking for more ALIVFibr Internet plans?
      </a>
    </div>
    <CheckAliv class="section-margin"></CheckAliv>
  </div>
</template>

<script>
import BundleDetailsLong from "@/components/common/BundleDetailsLong.vue";
import FibrProductBanner from "@/components/common/FibrProductBanner.vue";
import FeatureDescription from "@/components/common/FeatureDescription.vue";
import SecondaryFeature from "@/components/fibr-products/SecondaryFeature.vue";
import ImageWithText from "@/components/common/ImageWithText.vue";
import CheckAliv from "@/components/common/CheckAliv.vue";
import entryId from "@/config/entryid.json";
import { getEntryFromArray } from "@/util/contentUtil.js";
import router from "@/router";

export default {
  name: "FibrInternet",
  inject: ["$contentful"],
  components: {
    FibrProductBanner,
    FeatureDescription,
    BundleDetailsLong,
    SecondaryFeature,
    ImageWithText,
    CheckAliv,
  },
  data() {
    return {
      banner: {
        title: "",
        description: "",
        imageUrl: "",
      },
      features: [],
      secondaryFeature: {
        title: "",
        description: "",
        imageUrl: "",
      },
      addons: {
        title: "",
        description: "",
      },
      speed: {
        title: "",
        description: "",
      },
      fibrAddon1: {
        title: "",
        document: {},
        imageUrl: "",
      },
      fibrAddon2: {
        title: "",
        document: {},
        imageUrl: "",
      },
      bundles: [
        {
          name: "",
          description: "",
          imageUrl: "",
          details: [],
          vat: "",
          price: "",
          isOpen: false,
          id: "",
        },
      ],
      viewAll: "",
    };
  },
  created() {
    this.loadPageContent();
    this.$contentful
      .getEntry(
        entryId.fibrInternetPage.fibrAddons.upgradedOpticalNetworkTerminal
      )
      .then((val) => {
        const fields = val.fields;
        this.fibrAddon1.title = fields.title;
        this.fibrAddon1.document = fields.description;
        this.fibrAddon1.imageUrl = fields.media?.fields?.file?.url;
      });

    this.$contentful
      .getEntry(entryId.fibrInternetPage.fibrAddons.meshWiFiExtender)
      .then((val) => {
        const fields = val.fields;
        this.fibrAddon2.title = fields.title;
        this.fibrAddon2.document = fields.description;
        this.fibrAddon2.imageUrl = fields.media?.fields?.file?.url;
      });
  },
  methods: {
    loadPageContent() {
      this.$contentful
        .getEntry(entryId.fibrInternetPage.fibrInternetPageContent)
        .then((val) => {
          const fields = val.fields.body;
          const bannerInfo = getEntryFromArray(
            fields,
            entryId.fibrInternetPage.fibrInternetFutureofHome
          );
          this.banner.title = bannerInfo.title;
          this.banner.description = bannerInfo.description;
          this.banner.imageUrl = bannerInfo.backgroundImage?.fields?.file?.url;
          this.viewAll = val.fields.url;

          this.loadFeatures(fields);
          this.loadSecondaryFeature(fields);
          this.loadAddons(fields);
          this.loadSpeed(fields);
        });
      this.loadBundles();
    },
    loadFeatures(fields) {
      const featureInfo1 = getEntryFromArray(
        fields,
        entryId.fibrInternetPage.futureReadyTech
      );
      const featureInfo2 = getEntryFromArray(
        fields,
        entryId.fibrInternetPage.fastInternetSpeed
      );
      const featureInfo3 = getEntryFromArray(
        fields,
        entryId.fibrInternetPage.oneGbpsDownloadSpeed
      );
      this.features.push({
        id: "1",
        title: featureInfo1.title,
        description: featureInfo1.description,
      });
      this.features.push({
        id: "2",
        title: featureInfo2.title,
        description: featureInfo2.description,
      });
      this.features.push({
        id: "3",
        title: featureInfo3.title,
        description: featureInfo3.description,
      });
    },
    loadSecondaryFeature(fields) {
      const secondaryFeatureInfo = getEntryFromArray(
        fields,
        entryId.fibrInternetPage.lifeAtAnySpeed
      );
      this.secondaryFeature.title = secondaryFeatureInfo.title;
      this.secondaryFeature.description = secondaryFeatureInfo.description;
      this.secondaryFeature.imageUrl =
        secondaryFeatureInfo.backgroundImage?.fields?.file?.url;
    },
    loadAddons(fields) {
      const fibrAddonsInfo = getEntryFromArray(
        fields,
        entryId.fibrInternetPage.fibrAddons.addons
      );
      this.addons.title = fibrAddonsInfo.title;
      this.addons.description = fibrAddonsInfo.description;
    },
    loadSpeed(fields) {
      const speedInfo = getEntryFromArray(
        fields,
        entryId.fibrInternetPage.speedYourHomeDeserves.fibrSpeed
      );
      this.speed.title = speedInfo.title;
      this.speed.description = speedInfo.description;
    },
    loadBundles() {
      const bundleIds = ["1p-1000", "1p-500", "1p-250", "1p-100"]; // Add bundle IDs as required
      bundleIds.forEach((id, index) => {
        this.$contentful
          .getEntry(entryId.fibrInternetPage.speedYourHomeDeserves[id])
          .then((val) => {
            this.bundles[index] = {
              name: val.fields.bundleName,
              description: val.fields.description.content[0].content[0].value,
              price: val.fields.bundles[0].fields.price,
              vat: val.fields.bundles[0].fields.vat,
              details: val.fields.bundleDetails[0].fields.benefits,
              id: val.sys.id,
              isOpen: false,
            };
            val.fields.bundleDetails[0].fields.extraBundleDetailsIfApplicable.forEach(
              (b) => this.bundles[index].details.push(b)
            );
          });
      });
    },
    toggleDetails(bundle) {
      bundle.isOpen = !bundle.isOpen;
    },
    cardClicked(bundle) {
      router.push({
        name: "bundle-details",
        params: { bundle: encodeURI(bundle.name) },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.fibr-addon-title {
  color: #000;
  font-size: $font-l;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 32px;
}
.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.bundle-card {
  height: auto; /* Set to auto to accommodate content */
  border: 1px solid #e0e0e0;
  border-radius: $border-radius;
  display: block;
  position: relative;
  transition: height 800ms ease-in-out;
  overflow: hidden;
  padding-left: 32px;
  padding-right: 32px;
  text-align: center;
  &-expanded {
    height: auto;
    transition: height 800ms ease-in-out;
  }
}

.bundle-details {
  margin-top: 30px;
  margin-bottom: 40px;
}

.toggle-button {
  color: #635890;
  border: none;
  border-radius: 16px;
  background-color: white;
  width: 100%;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.view-details-arrow {
  transform: rotate(180deg);
  margin-left: auto;
  margin-right: auto;
  display: block;
  transition: transform 400ms;

  &-open {
    transform: rotate(0deg);
    transition: transform 400ms;
  }
}
</style>
