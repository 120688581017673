<template>
  <CarouselBanner></CarouselBanner>
  <div class="horizontal-page-margin">
    <FibrHeroProductContainer></FibrHeroProductContainer>
    <FibrBundlesContainer class="section-margin-small"></FibrBundlesContainer>
    <AboutFibr class="section-margin-small"></AboutFibr>
    <FibrHomeFaq class="section-margin"></FibrHomeFaq>
    <CheckAliv class="section-margin"></CheckAliv>
  </div>
</template>

<script>
import FibrHeroProductContainer from "@/components/fibr-home/hero/FibrHeroProductContainer.vue";
import FibrBundlesContainer from "@/components/fibr-home/bundles/FibrBundlesContainer.vue";
import AboutFibr from "@/components/fibr-home/about-fibr/AboutFibr.vue";
import FibrHomeFaq from "@/components/fibr-home/home-faq/FibrHomeFaq.vue";
import CheckAliv from "@/components/common/CheckAliv.vue";
import CarouselBanner from "@/components/fibr-home/banner/CarouselBanner.vue";

export default {
  name: "FibrHome",
  components: {
    FibrHeroProductContainer,
    FibrBundlesContainer,
    AboutFibr,
    FibrHomeFaq,
    CheckAliv,
    CarouselBanner,
  },
};
</script>
